body {
  margin: 0;
  font-family: "Gilroy", sans-serif !important;
}
* {
  font-size: 15px;
}
::-webkit-scrollbar {
  display: none;
}

/* -------------------- widths -------------------- */
.w-15 {
  width: 15%;
}
.w-85 {
  width: 85%;
}

/* -------------------- Border-colors -------------------- */
.border-orange {
  border-color: #f88001 !important;
}
.bd-10 {
  border-width: 10px !important;
}
.left-border {
  border-left: 5px dotted !important;
  /* height: "25px" !important; */
  border-color: gray !important;
}

/* -------------------- Text colors --------------------*/
.text-orange {
  color: #f88001;
}
.text-grey {
  color: #aaa;
}
.text-green {
  color: #38A038 !important;
}
.text-brown {
  color: #c65200 !important;
}

/* -------------------- Background colors -------------------- */
.bg-orange {
  background-color: #FE9C34;
}
.bg-light-orange {
  background-color: rgba(254, 216, 174, 0.25);
}
.bg-green {
  background-color: #38A038;
}
.bg-light-green {
  background-color: #EDF9EE;
}
.bg-light-grey {
  background-color: #eee;
}
.bg-grey {
  background-color: #bbb;
}
.bg-purple {
  background-color: #9370dbdd;
}
.bg-golden {
  background-color: #daa520dd;
}
.bg-tr {
  background-color: transparent;
}
.bg-snap-red {
  background-color: #fcd7ce;
}
.bg-snap {
  background-color: #e9f4e9;
}
.bg-yellow {
  background-color: #f7f2c7 !important;
}
/* -------------------- Transition -------------------- */
.animate {
  transition: all 0.3s ease;
}
.animate-2 {
  transition: all 0.5s ease;
}

/* -------------------- Login Popups -------------------- */
.hide {
  left: -100% !important;
  transition: 0.3s ease;
}
.hide-2 {
  left: -100% !important;
  transition: 0.3s ease;
}

/* -------------------- Buttons -------------------- */

.btn-cnf {
  outline: none;
  border: none;
  border-radius: 6px;
  transition: all 0.3s ease;
  font-size: 16px;
  line-height: 22px;
  padding: 13px 16px;
  font-weight: 700;
}
.btn-orange {
  background-color: #f88001;
  color: white;
}
.btn-orange:disabled {
  background-color: #c8c8c8;
  color: #fff;
}

.btn-default-orange {
  outline: none;
  border: 1px solid #c8c8c8;
  color: #666666;
  font-weight: 500;
  font-size: 18px;
  line-height: 130%;
  border-radius: 6px;
  background-color: white;
  text-align: center;
  text-decoration: none;
  transition: ease 0.4s;
}
.btn-default-orange:focus,
.btn-default-orange:active {
  border: 1px solid #f88001;
  color: #fff;
  background-color: #f88001;
}

.btn-outline-orange {
  outline: none;
  border: 2px solid #f88001;
  color: #f88001;
  border-radius: 5px;
  background-color: white;
}
.btn-green {
  background-color: #60b246;
  border: 1px solid #4b9933;
  color: #fff;
}
.btn-green:disabled {
  background-color: #d3dfdb;
  border: 1px solid #bcc0bf;
  color: rgb(131, 131, 131);
}
.btn-aqua {
  background-color: #00a86b;
  border: 1px solid #07915e;
  color: white;
}

/* -------------------- Font size -------------------- */
.fs-xxl {
  font-size: 10rem;
}
.fs-xl {
  font-size: 5rem;
}
.fs-xl-2 {
  font-size: 7rem;
}
.fs-lg {
  font-size: 36px !important;
}
.fs-md {
  font-size: 14px;
}
.fs-sm {
  font-size: 10px;
}

/* -------------------- Roundness --------------------*/
.round-top {
  border-radius: 17px 17px 0px 0px;
}
.round {
  border-radius: 10px;
}
.round-1 {
  border-radius: 17px;
}
.round-2 {
  border-radius: 0px 30px 30px 0px !important;
}

/* -------------------- Floating-label -------------------- */
div.floating-label {
  border: none;
}
input.floating-label {
  border-bottom: 2px solid #ddd !important;
}
input.floating-label:focus {
  border-bottom: 2px solid #f88001 !important;
}

/* -------------------- Others -------------------- */
.loading-screen {
  position: absolute;
  width: 100vw;
  top: 0vh;
  left: 0vw;
  background-color: #fff;
  z-index: 100000;
}

.h-scroll {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  width: 100%;
  overflow-x: scroll;
}

.v-scroll {
  overflow-y: scroll !important;
}

.fade-in {
  animation: zoom-fade 2s ease infinite;
}
@keyframes zoom-fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.animate-in {
  animation: in 0.4s ease;
}

.gap {
  padding: 40px;
}

.triangle {
  position: relative;
  width: 0;
  height: 0;
  right: -2px;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-right: 12px solid white;
}

/* -------------------- Shadow -------------------- */
.shadow-1 {
  box-shadow: 0px 0px 5px 1px #00000033;
}

/* @override bootstrap styles */
.btn {
  border: none;
  outline: none;
}
.btn:focus {
  box-shadow: none;
}
.btn:disabled {
  background-color: #898989;
  outline: none;
  border: none;
}
.form-select:active,
.form-select:focus {
  box-shadow: none !important;
  border: none !important;
  border-bottom: 2px solid #f88001 !important;
  outline: none;
}
.form-select {
  box-shadow: none !important;
  border: none !important;
  border-bottom: 2px solid #ddd !important;
  outline: none;
}

.form-control {
  border: none;
  outline: none;
}
.form-control:active,
.form-control:focus {
  box-shadow: none;
  border: 1px solid #f88001 !important;
}
.offcanvas {
  border: 0px !important;
}

.placed {
  position: fixed;
  top: 0vh;
  height: 100vh;
  width: 100vw;
  background-color: #5dc68e;
  transition: all 1s ease;
}

.zoom-in {
  animation: zoom 1s ease;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}

.dotted-hr {
  border: none;
  border-top: 3px dotted #ddd;
  color: #fff;
  background-color: #fff;
  height: 1px;
}

/* *********************** Naresh Jangid ************************** */
.popup-header {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.popup-header h2 {
  margin: 0;
  font-size: 18px;
  line-height: 20px;
  padding-top: 1px;
  color: #2b2b2b;
  font-weight: 700;
}
.popup-header i {
  font-size: 20px;
  margin-right: 8px;
}
.fs-5 {
  font-size: 1.15rem !important;
  line-height: 120%;
}
.logo {
  font-size: 40px;
  font-weight: 700;
  color: #121212;
  margin: 0 0 35px;
}
.logo span {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  margin: 0 auto 10px;
  padding: 8px;
  border-radius: 50%;
  background: hsla(0, 0%, 100%, 0.9);
}
.sep hr {
  display: block;
  border-top: 2px dashed #ddd;
  opacity: 1;
  background: transparent;
}
.sepCircle {
  max-width: 42px;
  height: 42px;
  line-height: 40px;
  font-weight: 700;
  color: #484848;
  text-transform: uppercase;
  margin: 0 20px;
  background: #fce3d2;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fce3d2;
  border: 2px solid #fff;
}

.splashhide {
  left: -100% !important;
}
.splash {
  border-radius: 0 0 600px 600px;
  height: 40vh;
  animation-name: example2;
  animation-duration: 4s;
}

@keyframes example2 {
  0% {
    height: 120vh;
    padding-bottom: 20vh;
  }
  80% {
    height: 120vh;
    padding-bottom: 20vh;
  }
  92% {
    border-radius: 0 0 50% 50%;
    height: 32vh;
  }
  100% {
    border-radius: 0 0 600px 600px;
    height: 40vh;
    margin-bottom: 0;
  }
}
.brandTitile {
  opacity: 0;
  animation-name: example;
  animation-duration: 2s;
}

@keyframes example {
  0% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.MuiFormControl-root{width: 100%;}
.MuiFormControl-root > .MuiInput-underline {
  margin: 0 !important;
  padding: 0;
}
.MuiFormControl-root > .MuiInput-underline:after {
  margin: 0 !important;
}

.otpverify {
  width: 184px;
  margin: 0 auto 8px;
  position: relative;
  overflow: hidden;
}
.otpverify .MuiInput-underline {
  padding: 0 !important;
  overflow: hidden;
}
.otpverify .MuiInput-underline:before,
.otpverify .MuiInput-underline:after,
.otpverify .MuiTextField-root:before,
.otpverify .MuiTextField-root:after {
  width: 40px;
  height: 2px;
  border-radius: 2px;
  background: #c8c8c8;
  border: none !important;
  position: absolute;
  bottom: 0;
  content: "";
  transition: ease 0.5s;
}
.otpverify .MuiInput-underline:before {
  left: 48px;
}
.otpverify .MuiInput-underline:after {
  left: 0;
  right: auto;
  transform: none;
}
.otpverify .MuiTextField-root:before {
  right: 48px;
}
.otpverify .MuiTextField-root:after {
  right: 0;
}
/* .otpverify .MuiInput-underline.Mui-focused:after, .otpverify .MuiInput-underline.Mui-focused:before, .otpverify .MuiInput-underline.Mui-focused .MuiTextField-root:after, .otpverify .MuiInput-underline.Mui-focused .MuiTextField-root:before{background: #FF2341;} */
.otpverify .MuiInput-underline input {
  line-height: 48px;
  height: auto;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 37px;
  text-indent: -37px;
  direction: rtl;
  padding-left: 14px;
  width: 184px;
}

.selectArea {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #666;
  position: relative;
  padding-left: 28px;
}
.selectArea.checked {
  font-weight: 700;
  color: #2b2b2b;
}
.selectArea:after {
  width: 14px;
  height: 14px;
  position: absolute;
  left: 1px;
  top: 50%;
  margin-top: -7px;
  border-radius: 50%;
  box-shadow: 0 0 0 1.5px #444;
  background: #fff;
  border: 3px solid #fff;
  transition: 0.5s ease;
  content: "";
}
.selectArea.checked:after {
  box-shadow: 0 0 0 1.5px #2c7c2c;
  background: #2c7c2c;
}

/* nav[data-bs-padding-right="15px"]{} */
.changeAdd-offset {
  top: 8px;
  bottom: auto;
  left: auto;
  right: 15px;
  opacity: 0;
  visibility: 0;
  z-index: -1;
  transform: none;
}
.changeAdd-offset .offcanvas-body {
  padding: 12px;
  margin-left: auto;
  width: 220px;
  border-radius: 8px;
  background-color: #fff;
  opacity: 0;
  transition: ease 0.4s;
  margin-top: 30px;
}
.changeAdd-offset .offcanvas-footer {
  margin-left: auto;
  width: 220px;
  display: flex;
  justify-content: center;
}
.offcanvas-body .selectArea {
  font-size: 12px;
  margin-bottom: 14px;
  padding-left: 24px;
}
.changeAdd-offset .offcanvas-body .selectArea:last-of-type {
  margin-bottom: 0;
}
.changeAdd-offset.show {
  opacity: 1;
  visibility: visible;
  z-index: 1045;
}
.changeAdd-offset.show .offcanvas-body,
.changeAdd-offset.show .offcanvas-footer {
  opacity: 1;
  margin-top: 0;
}
.changeLang-offset .offset-title {
  margin-left: auto;
  margin-bottom: 2px;
}

.vernac {
  margin-left: 8px;
  display: block;
  background: #f2f2f2;
  border: 1px solid #e1e1e1;
  border-radius: 24px;
  padding: 4px;
  width: 34px;
  height: 34px;
}
.vernac img {
  display: block;
  width: 24px;
}
.changeLang-offset {
  top: 45px;
}
.changeLang-offset .offcanvas-body {
  width: 90px;
}
.changeLang-offset .vernac {
  margin-left: auto;
  margin-bottom: 2px;
}

.homeCarousel {
  padding-left: 15px;
  padding-right: 3px;
  margin-top: 20px;
}
.homeCarousel .carousel-item {
  padding-right: 12px;
}
.carousel-indicators {
  position: static;
  display: flex;
  justify-content: center;
  height: auto;
  margin-top: 10px;
}
.carousel-indicators [data-bs-target] {
  display: inline-flex;
  width: 6px;
  height: 6px;
  background: #c8c8c8;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 2px;
  position: relative;
  border: 0;
  transition: ease 0.4s;
}
.carousel-indicators [data-bs-target].active {
  width: 12px;
  background-color: #444;
}

/* Don't need button navigation */
.card-wrap {
  padding: 12px 16px 8px;
}
.sec-title {
  margin-bottom: 8px;
}
.sec-title h2 {
  margin: 0 0 2px;
  font-size: 18px;
  line-height: 130%;
  font-weight: 700;
  color: #2b2b2b;
}
.sec-title h4 {
  margin: 0;
  font-size: 14px;
  line-height: 130%;
  font-weight: 500;
  color: #4a4a4a;
}
.sec-title a {
  font-size: 12.5px;
  text-decoration: none;
  line-height: 130%;
  font-weight: 500;
  color: #2278ff;
}
.sec-title a i {
  padding: 0 4px;
}

.horiz-scroll {
  overflow: auto;
  margin-right: -16px;
  display: flex;
  padding: 4px 0;
  max-width: 100%;
}
.horiz-scroll > .card {
  border-radius: 8px;
  border: 0;
  margin: 0 8px 0 0;
  min-width: 108px;
  width: 108px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}
.horiz-scroll > .card .card-top-image {
  padding: 5px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 114px;
}
.horiz-scroll > .card .card-top-image img {
  width: auto !important;
  max-width: 100%;
  max-height: 100%;
}
.horiz-scroll > .card > .card-body {
  padding: 8px;
  position: relative;
}
.horiz-scroll > .card > .card-body h3 {
  font-size: 14px;
  color: #2b2b2b;
  font-weight: 500;
  letter-spacing: -0.3;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.horiz-scroll > .card > .card-body .item-weight {
  font-size: 12px;
  color: #666666;
  font-weight: 500;
  letter-spacing: -0.3px;
}
.horiz-scroll > .card h5 {
  font-size: 14px;
  color: #999999;
  font-weight: 500;
  letter-spacing: -0.3px;
  margin: 0;
}
.horiz-scroll > .card h5 strong {
  font-size: 16px;
  color: #2b2b2b;
  font-weight: 800;
  letter-spacing: -0.3px;
  margin: 0;
  text-decoration: none;
  padding-right: 4px;
}
.horiz-scroll > .card .badge-success {
  font-size: 11px;
  background-color: #e9f4e9;
  color: #1f591f;
  padding: 2px 4px;
  border-radius: 20px;
  font-weight: 500;
  letter-spacing: -0.3px;
  margin: 0;
  text-decoration: none;
}
.horiz-scroll > .card a {
  display: block;
  position: absolute;
  bottom: 100%;
  right: 0;
  border-radius: 8px;
  width: 32px;
  height: 32px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 32px;
  font-size: 18px;
  background-color: #f88001;
  color: #ffffff;
  font-weight: 700;
  text-decoration: none;
}
.horiz-scroll > .card a i {
  line-height: 32px;
  font-size: 32px;
}
.horiz-scroll > .card a i:before {
  font-weight: 700 !important;
}
.combo-wrap .horiz-scroll > .card {
  background: #e6f0ff;
  box-shadow: none;
  padding: 0 15px;
  color: #1a5dc6 !important;
  position: relative;
  min-width: 240px;
  border-radius: 8px;
  overflow: hidden;
}
.combo-wrap .horiz-scroll > .card:before {
  background: url(./img/combo-blue-bg.png) no-repeat right center;
  background-size: auto 100%;
  position: absolute;
  top: 15px;
  right: 0;
  width: 100%;
  height: 100%;
  content: "";
}
.combo-wrap .horiz-scroll > .card > .card-body {
  padding: 12px 0;
}
.combo-wrap .horiz-scroll > .card > .card-body img {
  max-height: 88px;
  max-width: 97px;
}
.combo-wrap .horiz-scroll > .card > .card-body h3 {
  color: #1a5dc6;
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;
}
.combo-wrap .horiz-scroll > .card > .card-body ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.combo-wrap .horiz-scroll > .card > .card-body ul li {
  font-size: 10px;
  color: #1a5dc6;
  font-weight: 600;
  line-height: 130%;
}
.combo-wrap .horiz-scroll > .card .card-footer {
  border-top: 1px solid #1a5dc6;
  background: transparent;
  padding: 8px 0;
  position: relative;
}
.combo-wrap .horiz-scroll > .card .card-footer a {
  position: static;
  margin-left: 10px;
  border-radius: 6px;
  padding: 10px 22px;
  width: 92px;
  height: auto;
  color: #fff;
  font-size: 14px;
  line-height: 130%;
  font-weight: 700;
  min-width: 92px;
  box-shadow: none;
}
.combo-wrap .horiz-scroll > .card .card-footer .badge-success {
  border-radius: 3px;
  display: block;
}
.combo-wrap .horiz-scroll > .card .card-footer h5 {
  font-size: 14px;
  line-height: 130%;
  margin: 0;
  color: #1a5dc6;
}
.combo-wrap .horiz-scroll > .card .card-footer h5 strong {
  color: #1a5dc6;
}

.combo-wrap .horiz-scroll > .card-blue {
  background: #e6f0ff;
}
.combo-wrap .horiz-scroll > .card-blue > :before {
  background-image: url(./img/combo-blue-bg.png);
}
.combo-wrap .horiz-scroll > .card-blue > .card-body h3,
.combo-wrap .horiz-scroll > .card-blue > .card-body ul li,
.combo-wrap .horiz-scroll > .card-blue > .card-footer h5,
.combo-wrap .horiz-scroll > .card-blue > .card-footer h5 strong {
  color: #1a5dc6;
}
.combo-wrap .horiz-scroll > .card-blue > .card-footer {
  border-top-color: #1a5dc6;
}

.combo-wrap .horiz-scroll > .card-red {
  background: #f8dddd;
}
.combo-wrap .horiz-scroll > .card-red > :before {
  background-image: url(./img/combo-red-bg.png);
}
.combo-wrap .horiz-scroll > .card-red > .card-body h3,
.combo-wrap .horiz-scroll > .card-red > .card-body ul li,
.combo-wrap .horiz-scroll > .card-red > .card-footer h5,
.combo-wrap .horiz-scroll > .card-red > .card-footer h5 strong {
  color: #df5353;
}
.combo-wrap .horiz-scroll > .card-red > .card-footer {
  border-top-color: #df5353;
}

.combo-wrap .horiz-scroll > .card-green {
  background: #e9f4e9;
}
.combo-wrap .horiz-scroll > .card-green > :before {
  background-image: url(./img/combo-green-bg.png);
}
.combo-wrap .horiz-scroll > .card-green > .card-body h3,
.combo-wrap .horiz-scroll > .card-green > .card-body ul li,
.combo-wrap .horiz-scroll > .card-green > .card-footer h5,
.combo-wrap .horiz-scroll > .card-green > .card-footer h5 strong {
  color: #2c7c2c;
}
.combo-wrap .horiz-scroll > .card-green > .card-footer {
  border-top-color: #2c7c2c;
}

.staticpage {
  padding: 15px;
}
.staticPage h1 {
  margin: 0 0 1.5em;
  font-weight: 600;
  font-size: 1.5em;
}
.searchinput::-webkit-placeholder,
.searchinput::placeholder {
  color: #aaaaaa;
}
.searchinput {
  color: #2b2b2b;
}
.searchinput:focus,
.searchinput:active {
  border: 0 !important;
  outline: none;
}

.recent-search .btn {
  border: 1px solid #c8c8c8;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 8px;
  margin: 0 8px 12px 0;
  display: inline-flex;
  align-items: center;
}
.recent-search .btn:focus {
  border: 1px solid #6c757d;
}
.recent-search .btn img {
  margin: 0 4px 1px 0;
}

.search-wrap .searchlistback {
  background: rgba(0, 0, 0, 0.75);
  backdrop-filter: none;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}
.search-wrap .searchlist {
  padding: 12px 16px 0;
  margin: 0;
  border-radius: 0 0 12px 12px;
  background: #ffffff;
  width: 100%;
}
.search-wrap .searchlist li {
  margin: 0 0 15px;
  height: auto;
  padding: 0;
  font-size: 14px;
  color: #2b2b2b;
  font-weight: 500;
}

.icon-menu.bi-house-fill:before,
.icon-menu.bi-clipboard-data:before,
.icon-menu.bi-person-circle:before {
  content: "";
}
.icon-menu.bi-house-fill {
  background: url(./img/icon-nav-home.png) center center;
  background-size: 24px;
}
.active .icon-menu.bi-house-fill {
  background-image: url(./img/icon-nav-home-active.png);
}
.icon-menu.bi-clipboard-data {
  background: url(./img/icon-nav-category.png) center center;
  background-size: 24px;
}
.active .icon-menu.bi-clipboard-data {
  background-image: url(./img/icon-nav-category-active.png);
}
.icon-menu.bi-person-circle {
  background: url(./img/icon-nav-account.png) center center;
  background-size: 24px;
}
.active .icon-menu.bi-person-circle {
  background-image: url(./img/icon-nav-account-active.png);
}

.moreItems {
  width: 108px;
  min-width: 108px;
}
.moreItemsCircle {
  overflow: hidden;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  border: 2px solid #fff;
  background: #f2f2f2;
  margin-bottom: 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.025);
}
.moreItemsCircle img {
  width: 100%;
}
.viewall-link {
  font-size: 12.5px;
  text-decoration: none;
  line-height: 130%;
  font-weight: 500;
  color: #2278ff;
  padding: 0;
  outline: none;
  background-color: transparent;
  border: 0;
}
.moreItems .moreItemsCircle + .moreItemsCircle {
  margin-left: -14px;
}

/* .offcanvas-bottom{z-index: 1032;} */
.offcanvas .bi-x-circle {
  width: 24px;
  height: 24px;
  margin: 12px auto;
}
.offcanvas .bi-x-circle:before {
  content: ""; display: block;
  background: url(./img/close-circle.png) no-repeat center center;
  background-size: 24px 24px;
  width: 24px;
  height: 24px;
}
.offcanvas-bottom .offcanvas-body {
  box-shadow: 0 -2px 4px rgba(51, 51, 51, 0.1);
  border-bottom: 1px solid #f2f2f2 !important;
  border-radius: 8px 8px 0 0;
}
.homecart-offset.show {
  transform: translateY(-52px);
}
.titleImg {
  width: 42px;
  margin-bottom: 10px;
  mix-blend-mode:multiply
}
.card-bottom h3 {
  font-size: 11px;
  font-weight: 500;
  color: #666666;
  margin: 0 0 4px;
}
.card-bottom .bagicon {
  width: 24px;
  margin-right: 8px;
}
.card-bottom h5 {
  font-size: 12px;
  color: #999999;
  font-weight: 500;
  letter-spacing: -0.3px;
  margin: 0 5px 0 0;
}
.card-bottom h5 strong {
  font-size: 14px;
  color: #2b2b2b;
  font-weight: 800;
  letter-spacing: -0.3px;
  margin: 0;
  text-decoration: none;
  padding-right: 4px;
}
.card-bottom h5 del {font-size: 12px;}
.morebtn {
  border-radius: 6px;
  font-size: 14px;
  line-height: 20px;
  padding: 7px 32px;
  font-weight: 600;
  border: 0;
  font-family: "Gilroy", sans-serif;
}
.badge {
  font-weight: 500;
  padding-left: 4px;
  padding-right: 4px;
}
.badge-success {
  background-color: #2c7c2c;
}
.badge-warning {
  background-color: #8e6b24;
}
.badge-success-bordered {
  border: 1px solid #2c7c2c;
  color: #2c7c2c;
  background-color: #e9f4e9;
}
.badge-success-light {
  border-radius: 15px;
  font-weight: 500;
  color: #2c7c2c;
  background-color: #e9f4e9;
}
.badge-warning-bordered {
  border: 1px solid #c69532;
  color: #8e6b24;
  background-color: #fff8ea;
}
.popup-header .badge {
  padding: 1.5px 3px 0.5px;
  margin-left: 8px;
  line-height: 12px;
}

.combo-items .card-bottom {
  background: linear-gradient(90deg, #fef5eb 0%, rgba(254, 245, 235, 0) 100%);
  padding: 4px;
  margin-bottom: 12px;
  border-radius: 8px;
}
.combo-items .card-bottom h3 {
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  margin:0;
  color: #2b2b2b;
}
.combo-items .card-bottom h5 strong {
  color: #666666;
  font-weight: 700;
}
.combo-items + .d-flex .badge{margin-left: 4px; padding: 2px 5px;}
.combo-items .card-bottom .card-image {
  width: 46px;
  min-width: 46px;
  height: 46px;
  border-radius: 5.75px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}
.combo-items .card-bottom .card-image img {
  max-width: 100%;
  height: auto;
  max-height: 100%;
}
.plp-list {
  overflow: visible;
  flex-wrap: wrap;
  margin: 0;
  padding: 1px;
  background: #f6f6f6;
}
.plp-list .card {
  border-radius: 0;
  min-width: calc(50% - 2px);
  box-shadow: none;
  margin: 1px;
}
.plp-list .card a{
  right: 7px;
}
.plp-list .card .add-cart-num {
  right: 7px;
  width:calc(100% - 14px); justify-content: space-between;
}
.subcategory {
  width: 82px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
  position: fixed;
  left: 0;
  height: calc(100vh - 63px);
  overflow: auto;
  z-index: 1;
}
.subcategory .subcat-item {
  padding: 0 6px 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.subcategory .subcat-item:first-child {
  padding-top: 16px;
}
.subcategory .subcat-item:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  border-radius: 12px 0 0 12px;
  background: #f88001;
  opacity: 0;
  visibility: hidden;
}
.subcategory .subcat-item.active {
  padding: 16px 6px;
  background: #fef5eb;
}
.subcategory .subcat-item.active + .subcat-item {
  padding-top: 16px;
}
.subcategory .subcat-item.active:after {
  opacity: 1;
  visibility: visible;
}
.subcategory .subcat-item .subcat-img {
  width: 50px;
  height: 50px;
  background: #f2f2f2;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.subcategory .subcat-item.active .subcat-img {
  background: #fee1c2;
}
.subcategory .subcat-item .subcat-img img {
  width: 80%;
  height: auto;
}
.subcategory .subcat-item h4 {
  font-weight: 700;
  color: #666;
  font-size: 12px;
  text-align: center;
  margin: 4px 0 0;
}
.subcategory .subcat-item.active h4 {
  color: #2b2b2b;
}

.categoryWrap {
  margin-bottom: 52px;
}
.categoryWrap .secTitle h2 {
  margin-bottom: 12px;
}
.categoryRow {
  margin: 0 -5px;
  display: flex;
  flex-wrap: wrap;
}
.categoryRow .categoryCol {
  padding: 0 5px;
  width: 25%;
}
.categoryBox {
  margin: 0 0 12px;
  display: block;
  text-decoration: none;
}
.categoryBox .categoryImg {
  background: #fef5eb;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
}
.categoryBox .categoryImg img {
  display: block;
}
.categoryBox h4 {
  margin: 4px 0;
  font-size: 12px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

.changeCategory-offset {
  left: 0;
  right: 0;
  top: 63px;
}
.changeCategory-offset .offcanvas-body {
  width: 100%;
  border-radius: 0 0 8px 8px;
}

/* //plp styles */

.plp-pg {
  background: #ffffff;
  position: relative;
  margin-top: 63px;
  min-height: calc(100vh - 63px);
  padding-left: 82px;
}
.plp-procard {
  background: rgb(26, 93, 198);
  padding: 1px 6px;
  border-radius: 0 0 8px 0;
  position: absolute;
  top: 0px;
  left: 0px;
  color: rgb(255, 255, 255);
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
}
.plp-titlebar {
  padding: 13.5px 16px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.11);
  height: 63px;
}
.changeCategory-offset.show + .plp-titlebar {
  z-index: 1043;
}
.plp-titlebar h2 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  margin: 0 0 2px;
  color: #2b2b2b;
  font-weight: 700;
}
.plp-titlebar h5 {
  font-size: 12px;
  margin: 0;
  color: #f88001;
  font-weight: 500;
}
.plp-titlebar img {
  width: 24px;
}
.plpclose {
  width: 28px;
  position: fixed;
  top: 17px;
  left: 13px;
  padding: 2px;
  background-color: #ffffff;
}
.plpchevron {
  margin-left: 4px;
  width: 12px !important;
}
.plpbkicn {
  padding-right: 8px;
}
.add-cart-num {
  display: flex;
  position: absolute;
  background: #fff;
  bottom: 100%;
  right: -2px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 4px rgb(0 0 0 / 20%);
  text-align: center;
  text-decoration: none;
}
.add-cart-num i {
  width: 32px;
  line-height: 36px;
  height: 36px;
  font-size: 32px;
  text-align: center;
  background-color: #f88001;
  color: #ffffff;
  font-weight: 600;
}
.add-cart-num i:before {
  font-weight: 700 !important;
}
.add-cart-num > div {
  width: 58px;
  font-size: 18px;
  line-height: 36px;
  color: #000;
  font-weight: 700;
  background: #fff;
}
.pack-offers {
  border-top: 1px solid #f2f2f2;
  padding-top: 12px;
}
.pack-offers.combo-items .card-bottom {
  background: #f9f9f9;
  border-radius: 8px;
  padding: 0;
  overflow: hidden;
}
.pack-offers.combo-items .card-bottom:last-child {
  margin-bottom: 0;
}
.card-bottom .pack-offer-box {
  background: #2c7c2c;
  text-align: center;
  font-weight: 700;
  margin-right: 8px;
  color: #fff;
  letter-spacing: -0.23px;
  font-size: 11px;
  line-height: normal;
  padding: 8px 0;
  width: 46px;
}
.card-bottom .pack-offer-box strong {
  display: block;
  color: #ffc040;
  font-weight: 700;
}
.card-bottom .add-cart-num {
  position: static;
  min-width: 122px;
  width: 122px;
}
.card-bottom .add-cart-num i:before {font-weight: 400 !important;}

.offcanvas-body div[class*="category_categoryWrap"] {
  padding: 0;
  margin: 0;
}
.offcanvas-body div[class*="category_categoryWrap"] .sec-title {
  display: none;
}

.horiz-scroll .add-cart-num i {
  line-height: 32px;
  height: 32px;
}
.horiz-scroll .add-cart-num > div {
  width: 40px;
  line-height: 32px;
}

.billSummaryList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.billSummaryList li {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #2b2b2b;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.billSummaryList li.list-subtotal {
  font-weight: 700;
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 0;
  text-transform: uppercase;
  border-top: 1px dashed #c8c8c8;
}
.billSummaryList li .badge-success {
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  padding: 2px 6px;
  background: #e9f4e9;
  color: hsl(120, 48%, 24%);
  border-radius: 20px;
}

.cart-bottom-sheet {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  border-radius: 8px 8px 0 0;
  padding: 8px 18px 8px 12px;
  box-shadow: 0px -2px 4px rgba(51, 51, 51, 0.1);
  margin: 0px auto;
  transition: transform 300ms linear 0s;
  transform: translate(0px);
  animation: cartBottomSheet 400ms ease;
}

.cart-bottom-rounded {bottom: 70px;
  left: 12px;
  right: 12px;
  border-radius: 8px;
  border: 1px solid #f8dddd;}

@keyframes cartBottomSheet {
  from {
    transform: translateY(150px);
  }

  to {
    transform: translateY(0px);
  }
}

.cart-bottom-sheet .morebtn{padding: 14px 16px; flex: 1 1 auto; margin-left: 12px;}

.billSummaryList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.billSummaryList li {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #2b2b2b;
  display: flex;
  align-items: center;
  padding-bottom: 16px;
}
.billSummaryList li.list-subtotal {
  font-weight: 700;
  font-size: 16px;
  padding-top: 16px;
  padding-bottom: 0;
  text-transform: uppercase;
  border-top: 1px dashed #c8c8c8;
}
.billSummaryList li .badge-success {
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  text-transform: uppercase;
  padding: 2px 6px;
  background: #e9f4e9;
  color: #1f591f;
  border-radius: 20px;
}


.billSummaryList{list-style-type: none; padding: 0;margin: 0;}
.billSummaryList li{font-weight: 500;font-size: 14px;line-height: 130%; color: #2b2b2b; display: flex; align-items: center; padding-bottom: 16px;}
.billSummaryList li.list-subtotal{font-weight: 700;font-size: 16px;padding-top: 16px;padding-bottom: 0;text-transform: uppercase; border-top: 1px dashed #C8C8C8;}
.billSummaryList li .badge-success{font-weight: 700;font-size: 11px; line-height: 15px;text-transform: uppercase; padding: 2px 6px; background: #E9F4E9; color: #1F591F; border-radius: 20px; }

.orderReview .add-cart-num{border-radius: 4px;border:1px solid #C8C8C8; padding: 2px 4px; box-shadow: none; display: flex; align-items: center; width: 70px; min-width: 70px;}
.orderReview .add-cart-num > div{font-size: 15px; line-height: 22px; font-weight: 700; color: #f88001;}
.orderReview .add-cart-num i{min-width: 16px; height: 16px; line-height: 16px; font-size: 0;position: relative; background: transparent;}
.orderReview .add-cart-num i::before{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 1.5px; width: 10px; content: ''; background: #f88001;}
.orderReview .add-cart-num i.bi-plus::after{position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); width: 1.5px; height: 10px; content: ''; background: #f88001;}


.address-card{padding: 16px;margin-bottom: 16px;}
.address-card .sec-title{margin-bottom: 14px;}
.address-card .sec-title .badge{background:#E9F4E9; border-radius:14px; padding:2px 8px; font-size:14px; line-height:19px; font-weight:700; color:#1F591F}
.address-card .paytick{display: flex;align-items: center; width: 18px; min-width: 18px; height: 18px; border-radius: 50%; border: 1.5px solid #444444; background-color: #F9F9F9; }
.address-card .paytick img{width: 14px;}
.address-card .paytick.active{border-color: transparent; background-color: #2C7C2C; }

.addCard-item .addCard-img{width: 36px;min-width: 36px; height: 36px; margin-right: 8px; background: rgba(228, 228, 228, 0.5); border-radius: 4px; display: flex;justify-content: center; align-items: center;}
.addCard-item h4{font-size: 16px;font-weight: 700; color: #2b2b2b; line-height: 20px; letter-spacing: 0.2px;margin: 0;}
.addCard-item h5{font-size: 14px;font-weight: 400; color: #1B1215; line-height: 18px; letter-spacing: 0.2px;margin: 4px 0 0;}

.payMetohds{margin-bottom: 8px;}
.payMetohds .addCard-img{background-color: #fff; border: 1px solid #E4E4E4; width: 32px; min-width: 32px; height: 32px; display: flex; align-items: center; justify-content: center; border-radius: 4px;}
.payMetohds .addCard-item h4{font-weight: 600;}
.method-input {
  position: relative;
  margin-bottom: 16px;
  display: block;
}
.method-input input,
.method-input select,
.method-input .makeStyles-root-1 .MuiInput-input {
  background: #f9f9f9;
  width: 100%;
  border-radius: 4px;
  border: 0;
  border-bottom: 2px solid #c8c8c8;
  font-weight: 700;
  color: #2b2b2b;
  font-size: 14px;
  line-height: 20px;
  padding: 15px 10px;
}
.method-input input:disabled,
.method-input select:disabled,
.method-input .makeStyles-root-1 .MuiInput-input:disabled {
  padding: 23px 10px 7px;
}
.method-input input:focus,
.method-input select:focus,
.method-input .makeStyles-root-1 .MuiInput-input:focus {
  border-left: 0;border-right: 0;border-top: 0; outline: 0    ;
}
.method-input input.iWGVhM {
  border-bottom: 2px solid #c8c8c8;
  background: #f9f9f9;
}
.method-input input:focus {
  background: #f9f9f9;
}

.method-input .form-select{position:relative;background: #f9f9f9; padding: 23px 10px 7px;width: 100%; color: #2b2b2b; font-weight: 700; font-size: 14px;line-height: 20px;border-radius: 4px;}
.method-input .form-select:after{position: absolute; right: 15px; top: 50%; transform: rotate(45deg); margin-top: -5px; border-radius: 1px; border-bottom: 2px solid #999; border-right: 2px solid #999; content: ''; width:9px; height:9px;}

.method-input label.deqGRn {
  padding: 8px 10px;
  font-size: 12px;
}
.method-input .makeStyles-root-1 .MuiInput-underline:after,
.method-input .makeStyles-root-1 .MuiInput-underline:before {
  display: none;
}
.method-input select:focus-visible {
  outline: none;
}
.method-input input[readonly],
.method-input select[readonly],
.method-input .makeStyles-root-1 .MuiInput-input[readonly] {
  background: #e4e4e4;
  border-bottom-color: transparent !important;
}
.method-input .css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled{-webkit-text-fill-color: rgba(0, 0, 0, 1);}

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 9;
}
.bottom-bar > div {
  padding: 8px 16px;
  background-color: #fff;
}
.bottom-bar .btn-orange {
  padding: 14px 26px;
  border-radius: 6px;
  font-weight: 700;
}
.bottom-bar .btn-orange:disabled {
  background: #cfd8dc;
}

.paymethod-card{padding: 24px; margin-top: 16px; border-radius: 8px; border: 1px solid #E4E4E4; text-align: center;}
.paymethod-card .addCard-img{width: 48px; height: 48px;margin: 0 auto;}
.payMetohds h5{font-weight: 600; font-size: 12px; letter-spacing: 0.2px; color: #2b2b2b; margin:6px 0 0 ;}

.method-input .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{position: static;}
.method-input .makeStyles-root-1 .MuiInput-underline{padding: 0;}
.method-input .fnPwUp{height: auto;}
.method-input input,.method-input select,.method-input .makeStyles-root-1 .MuiInput-input{background: #F9F9F9; width: 100%; border-radius: 4px; border: 0; border-bottom: 2px solid #C8C8C8; font-weight: 700; color: #2B2B2B; font-size: 14px; line-height: 20px; padding: 23px 10px 7px; }
.method-input input.iWGVhM{border-bottom: 2px solid #C8C8C8; background: #F9F9F9;}
.method-input input:focus{ background: #F9F9F9; border: 0 !important; border-bottom: 2px solid #F88001 !important;}
.method-input label{padding: 8px 10px; color: #666666;position: absolute;top: 0;left: 0;width: 100%; height: 52px;overflow: hidden; transform: none; border-radius: 4px; pointer-events: none; font-weight: 500; font-size: 12px; line-height: 20px; text-align: left; transition: ease 0.4s;}
.method-input label.deqGRn, .method-input input:focus + label, .form-floating input:not(:empty)~label, .form-floating input.inputEditable ~label{padding: 8px 10px; font-size: 12px;}
.method-input .makeStyles-root-1 .MuiInput-underline:after, .method-input .makeStyles-root-1 .MuiInput-underline:before{display: none;}
.method-input select:focus-visible{outline: none;}
.method-input input[readonly], .method-input select[readonly], .method-input .makeStyles-root-1 .MuiInput-input[readonly]{background: #E4E4E4; border-bottom-color: transparent !important;}


.bottom-bar{position: fixed; bottom: 0;width: 100%; z-index: 9;}
.bottom-bar > div{padding: 8px 16px; background-color: #fff}
.bottom-bar .btn-orange{padding: 14px 26px; border-radius: 6px; font-weight: 700;}
.bottom-bar .btn-orange:disabled{background: #CFD8DC;}

.custom-modal .modal-dialog{margin: 16px;}
.custom-modal .modal-content{border-radius: 8px; border: 0; padding: 24px 16px;}
.custom-modal .modal-header{border: 0; padding: 0; align-self: center;}
.custom-modal .modal-footer, .custom-modal .modal-body{padding: 0; align-self: center;}
.custom-modal .modal-title{font-size: 16px; font-weight: 700; text-align: center; color: #2B2B2B; margin: 0 0 32px;}
.custom-modal .btn{border-radius:8px; font-size: 14px; line-height: 20px; padding: 9px 26px; font-weight: 600; color: #2B2B2B;border: 1px solid #C8C8C8;}
.custom-modal .btn:focus, .custom-modal .btn:hover{color: #fff;}
.custom-modal .btn-outline-danger{color: #BE2323;border: 1px solid #BE2323;}
.custom-modal .btn-outline-danger:focus, .custom-modal .btn-outline-danger:hover{color: #fff;}

.thankYou .splash{animation: none;}
.savingthankyou{background: linear-gradient(180deg, #FFEBC0 0%, #FFFFFF 100%); text-align: center; padding: 12px 16px; transform: translateY(0);
animation-name: savingSlideUp;
animation-duration: 1s;}
.savingthankyou h3{font-size: 24px; font-weight: 800; color: rgba(223, 83, 83, 1); margin: 0 0 2px;}
.savingthankyou h5{font-size: 16px; font-weight: 700; color: rgba(43, 43, 43, 1); margin: 0;}
@keyframes savingSlideUp {
  0% {
    transform: translateY(200%);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: translateY(0%);
  }
}
.account-list{margin-top: 74px;}
.account-list > a, .account-list .account-list-item{padding: 8px 16px; margin-bottom: 4px; border-bottom: 1px solid #E3E3E3; color: #2B2B2B; display: flex; align-items: center; }
.account-list > a h4, .account-list .account-list-item h4{font-size: 14px; font-weight: 600; margin: 0 0 2px;}
.account-list > a p, .account-list .account-list-item p{font-size: 12px; font-weight: 400; margin: 0;}
.account-list > a .bi-chevron-right, .account-list .account-list-item .bi-chevron-right{background: url(./img/right-chevron.png) center center no-repeat; background-size: 24px 24px; width: 24px; height: 24px;}
.account-list > a .bi-chevron-right:before, .account-list .account-list-item .bi-chevron-right:before{content: '';}
.account-list .account-list-icon{margin-right: 8px; width: 24px;}


.accountOrders-list h2{background: rgba(189, 189, 189, 0.15); font-size: 14px; font-weight: 500; margin: 12px 0 0; color: #2b2b2b; padding: 8px 16px;}
.accountOrders-list .card{border-radius: 6px; border-color: #E1E1E1; margin-bottom: 16px;}

.offcanvas-header + .offcanvas-body .btn{padding: 10px 20px; font-weight: 600; border-radius: 6px; letter-spacing: 0.5px;}
.offcanvas-header{padding: 16px 16px 0; border-bottom: 1px solid rgba(200, 200, 200, 0.5); margin-bottom: -1px; background: #fff; border-radius: 8px 8px 0 0;}
.offcanvas-header .offset-title{font-size: 14px; font-weight: 700; color: #2b2b2b; width: 100%; border-bottom: 1px solid rgba(200, 200, 200, 0.5); padding-bottom: 16px; margin: 0;}
.offcanvas-header + .offcanvas-body{border-radius: 0;box-shadow: none;}
.offcanvas-bottom .selectArea{font-size: 14px; color: #666666; font-weight: 500;}
.offcanvas-bottom .selectArea.checked{font-size: 14px; color: #2B2B2B; font-weight: 700;}

.btn-support{background: #fff; color: #2b2b2b; text-align: center;width: 50%; display: flex; align-items: center; text-decoration: none; border:1px solid #C8C8C8; font-size: 14px; font-weight: 600; border-radius: 8px;}
.btn-support:before{width: 38px; height: 38px; content: ''; border-radius: 3px;}
.btn-support.bi-telephone:before{background: rgba(34, 120, 255, 0.1) url(./img/icon-callus.png) no-repeat center; background-size: 24px auto;}
.btn-support.bi-whatsapp:before{background: rgba(72, 200, 87, 0.1) url(./img/icon-whatsapp.png) no-repeat center; background-size: 24px auto;}

.orderStatus{padding: 0;margin: 0;list-style-type: none;}
.orderStatus li{position: relative; padding: 5px 0; font-size: 14px; font-weight: 700; }
.orderStatus li:before{display: block; position: absolute; top: 0; left: 24px; width: 1.5px; height: 100%; transform: translateX(-50%); background: #2B2B2B; content: '';}
.orderStatus li:first-child:before{top: 50%;}
.orderStatus li:last-child:before{height: 50%;}
.orderStatus .orderStatusIcon{width: 48px; height: 48px; display: flex; align-items: center; justify-content: center; position: relative; margin-right: 12px;}
.orderStatus .orderStatusIcon:after{display: block; width: 19px; height: 19px; background: #F9F9F9; content: '';position: relative; border-radius: 50%; border: 2.5px solid #444444;}
.orderStatus li.completed .orderStatusIcon:after{ background: #2C7C2C url(./img/icon-tick.png) center no-repeat; background-size: 12px auto; border: 2.5px solid #2C7C2C; }
.orderStatus .orderStatusIcon img{display: none; position: absolute; top: 50%; left: 50%; width: 40px; height: 40px; transform: translate(-50%, -50%); content: '';z-index: 1;}
.orderStatus li.active .orderStatusIcon img{display: block;}
.orderStatus li.active span{color: #F88001;}
.orderStatus li.active span:after{color: #F88001; content: '...';}
.orderStatus li.completed span{display: none; }
.orderStatus li.completed .d-none{display: block !important}

.trackCarousel-wrap{padding: "8px 16px"; border-radius:"8px 8px 0 0 "; margin-bottom:"15px";
  background: "linear-gradient(180deg, #FFEBC0 0%, #FFFFFF 100%)"}
.profitsCarousel .carousel-inner{border:1px solid rgba(200, 200, 200, 0.5); border-radius: 8px; overflow: hidden; background: #fff;}
.profitsCarousel .carousel-caption{position: relative; left: auto; right: auto; bottom: auto; padding: 24px 0; overflow: hidden;}
.profitsCarousel .carousel-caption:before{position: absolute; bottom: 0; left: 50%; transform: translateX(-50%); border-radius: 50%; content: ''; background: #DF5353; width: 120%; height: 180%; }
.profitsCarousel .carousel-inner > .carousel-item:nth-child(2) .carousel-caption:before{background: #C89BFF;}
.profitsCarousel .carousel-inner > .carousel-item:nth-child(3) .carousel-caption:before{background: #38A038;}
.profitsCarousel .carousel-caption h5{font-size: 24px; font-weight: 800; position: relative; margin: 0 0 4px;}
.profitsCarousel .carousel-caption p{font-size: 14px; font-weight: 700; position: relative; margin: 0;}
.profitsCarousel .carousel-indicators{margin: 12px 0 0}
.profitsCarousel .carousel-indicators [data-bs-target].active{width: 6px; background-color: #F88001;}

.btn-blue{color: #fff; background: #2278FF;}
.btn-outline-blue{color: #2278FF !important; background: transparent; border-radius: 6px; border: 1px solid #2278FF !important;}
.disabled.btn-outline-blue{color: rgba(102, 102, 102, 0.4);  border: 1px solid rgba(102, 102, 102, 0.4);}
.disabled.btn-outline-blue img{filter: grayscale(100%); opacity: 0.5;}
.login-btns .btn-outline-blue{margin-top: 24px;padding: 12px 24px;display: flex; align-items: center; justify-content: center;}

.thankYouModal .modal-dialog{width: 245px; margin: auto;}
.thankYouModal .btn-close{position: absolute; top: -16px; right: -8px; padding: 0;opacity: 1;width: 24px; height: 24px; background: transparent; }
.thankYouModal .modal-title{margin-top: 16px;}

.home-callnow-wrap{padding: 14px 16px 0;}
.home-callnow-card{display: flex; align-items: center; border: 1px solid #E1E1E1; overflow: hidden; border-radius: 8px;}
.home-callnow-card img{margin-right: 8px;}
.home-callnow-card h4{font-size: 14px; font-weight: 700; color: #2b2b2b; margin: 0;}
.home-callnow-card p{font-size: 11px; font-weight: 400; color: #2b2b2b; margin: 0;}
.home-callnow-card .btn{padding: 8px; border-radius: 6px; font-size: 12px; font-weight: 700; white-space: nowrap; margin-right: 8px;}

.call-order-btn{border: 0; outline: none; background: transparent;border-radius: 50%; overflow: hidden; margin-left: 12px; padding: 0; width: 24px;min-width: 24px;}
.callOptions{list-style-type: none;margin: 0;padding: 16px;}
.callOptions li{padding: 2px 8px; overflow: hidden; border-radius: 4px; display: flex; align-items: center; margin-bottom: 16px;}
.callOptions li img{margin-right: 8px;}
.helpOrder-bg{background: #E9F1FF;}
.helplanguage-bg{background: rgba(72, 200, 87, 0.1);}
.helpOffer-bg{background: rgba(254, 166, 72, 0.1);}

.refer-top-banner{padding: 24px 16px; background: linear-gradient(rgb(255, 235, 192) 0%, rgb(255, 255, 255) 100%);}
.refer-top-banner h2{overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 24px; margin: 0px 0px 2px; color: rgb(223, 83, 83); font-weight: 800;}
.refer-top-banner h5{font-size: 14px; margin: 0px; color: rgb(74, 74, 74); font-weight: 500;}

.refer-features{margin: 111px 16px 16px; background: #F2F2F2; border-radius: 8px; padding: 0 16px 24px;}
.refer-features h2{font-size: 12px; font-weight: 600; color: rgba(43, 43, 43, 0.6); margin: 16px 0 0 ;}
.refer-card{margin-top: 16px; background: #fff; border: 1px solid #C8C8C8; overflow: hidden; border-radius: 8px; display: flex;}
.refer-card div[class*="-bg"]{width: 40px;display: flex;}
.refer-card h5{font-size: 14px; font-weight: 600; color: #2B2B2B; margin: 0; padding: 6px 8.5px;}
.btn-washare{display: block; margin: 0 16px; background: #4AC959; border-radius: 6px; display: flex; justify-content: center; align-items: center; font-size: 16px; font-weight: 700; padding: 12px 30px; color: #fff;}

.rewards-title{padding: 10px 16px; background: rgba(189, 189, 189, 0.15); font-size: 14px; font-weight: 500; color: #2b2b2b; margin: 48px 0 0;}
.rewards-box{padding: 16px; margin: 16px; border-radius:8px; background: rgba(156, 47, 70, 1); overflow: auto; position: relative;}
.rewards-box:before{content: ''; position: absolute; border-radius: 50%; background: #A7384C; width: 400px; height: 240px; transform: translateY(-40%); top: 100%; right: 50px;}
.rewards-box h5{font-size: 14px; font-weight: 700; color: #fff; margin: 0 0 16px; position: relative; z-index: 1;}
.rewards-box strong{font-size: 32px; line-height: 32px; font-weight: 800; color: #fff; margin: 0; position: relative; z-index: 1;}

.rewards-list{list-style-type: none;padding: 0;margin-bottom: 84px;}
.rewards-list li{box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.05); padding: 8px 16px; display: flex; align-items: center; background: #fff; margin-bottom: 1px;}
.rewards-list li h4{font-size: 14px; font-weight: 600; color: #2B2B2B; margin: 0 0 8px;}
.rewards-list li p{font-size: 14px; font-weight: 500; color: rgba(43, 43, 43, 0.75); margin: 0;}

.refer-offer {border: 1px solid #E1E1E1; display: flex; align-items: center; background: #fff; overflow: hidden; padding-left: 16px; border-radius: 8px; margin:0 16px 26px;}
.refer-offer h4{font-size: 16px; font-weight: 800; color: #DF5353; margin: 0 0 6px;}
.refer-offer p{font-size: 14px; font-weight: 600; color: rgba(43, 43, 43, 0.8); margin: 0;}



/* Rs 1 Page Styles **************** */

.header-bar{padding:13.5px 16px;box-shadow:0px 1px 0px rgba(0, 0, 0, 0.11) }
.header-bar + *{margin-top: 51px;}
.rs1-main-image{padding: 8px 15px; margin-bottom: 16px;}
.rs1-status{padding: 8px 16px;margin-bottom: 16px;}
.rs1-status .card{background: rgba(248, 128, 1, 0.1); border:0; border-radius: 8px; padding: 16px;}
.rs1-status .card h4{color: #F88001; font-size: 18px; font-weight: 700;margin: 0 0 10px;}
.rs1-status .card .progress{background: rgba(248, 128, 1, 0.15); height: 8px; border-radius: 12px; font-size: 18px; font-weight: 700;margin: 0 0 10px;}
.rs1-status .card .progress .progress-bar{background: #F88001;}
.rs1-status .card p{color: #404040; font-size: 11px; font-weight: 500;margin: 0;}
.rs1-status .card p strong{ font-size: 11px; font-weight: 700;}

.rs1-benefits{padding: 8px 16px;}
.rs1-benefits .row{margin: 0 -8px;}
.rs1-benefits .row > div{padding: 0 8px;}
.rs1-benefits h4{background: linear-gradient(98.96deg, #548AD8 16.92%, #8A4BD3 85.29%); border-radius: 20px; padding: 20px;color: #fff; font-size: 14px; line-height: 22px; font-weight: 600;margin: 2px 0 8px; text-align: center;}
.rs1-benefits .row > div:nth-child(2) h4{background: linear-gradient(98.96deg, #F33E62 16.92%, #F79334 85.29%);}
.rs1-benefits .row > div:nth-child(3) h4{background: linear-gradient(98.96deg, #893E9C 16.92%, #F82B73 85.29%);}
.rs1-benefits .row > div:nth-child(4) h4{background: linear-gradient(98.96deg, #D39646 16.92%, #CCCCCD 85.29%);}

.rs1-faqs{margin-bottom: 16px;}
.rs1-faqs .sec-title{padding: 12px 16px; background: linear-gradient(180deg, #FFEBC0 0%, rgba(255, 244, 221, 0.536458) 46.35%, rgba(255, 255, 255, 0) 100%); box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.11);}
.rs1-faqs .accordion .accordion-item{border-radius: 0;border: 0; margin-bottom: 8px; box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.11);}
.rs1-faqs .accordion .accordion-item .accordion-header span{padding: 4px 16px; display: flex;width: 100%; align-items: center;font-size: 14px; line-height: 22px; font-weight: 600; color: #2B2B2B;}
.rs1-faqs .accordion .accordion-item .accordion-body{ padding: 4px 16px 8px;font-size: 14px; font-weight: 400; line-height: 20px; color:rgba(43, 43, 43, 0.6)}
.rs1-faqs .accordion .accordion-item .accordion-header span:after{width: 16px; height: 16px; content: ''; background: url(img/icon-drop-arrow-sm.png) no-repeat center; background-size: 16px auto;margin-left: auto;}
.rs1-faqs .accordion .accordion-item .accordion-header span[aria-expanded="true"]:after{transform: scaleY(-1);}

.bg-gradient-orange{background: linear-gradient(180deg, #FFEBC0 0%, #FFFFFF 100%);}
.bg-gradient-green{background: linear-gradient(180deg, #E6F0FF 0%, #FFFFFF 100%);}
.bg-gradient-blue{background: linear-gradient(180deg, #BDDFBD 0%, rgba(189, 223, 189, 0) 100%);}
.bg-gradient-cyan{background: linear-gradient(180deg, #98F6DD 0%, #F9FFFD 100%);}
.bg-gradient-red{background: linear-gradient(180deg, #F9DFDF 0%, #FFFDFD 100%);}

.rs1-active-tab{padding: 8px; border-radius: 30px;background: #fff; display: flex;align-items: center;}
.rs1-active-tab span{color: #05CC8A; font-size: 12.5px; font-weight: 500; padding-right:6px; text-transform: uppercase;}
.rs1-active-tab span:after{border: #05CC8A; font-size: 12.5px; text-transform: uppercase;}
.rs1-locked{background: rgba(43, 43, 43, 0.75); border-radius: 8px; display: flex; flex-direction: column; align-items: center; justify-content: center; position: absolute; left: 0; top:0; z-index: 99; width: 100%; height: 100%;}
.rs1-locked p{color: #fff; font-size: 11px; font-weight: 500; margin:7px 0 0;}

.rs1-bottombar{backdrop-filter: blur(4px); background: rgba(255, 255, 255, 0.7); padding:8px 16px 10px; border: 2px solid #fff; position: relative;}
.rs1-bottombar:after{border-right:1px solid #1F591F;border-bottom:1px solid #1F591F; width: 8px; height: 8px; content: ''; position: absolute; right: 40px; top: 50%;transform:rotate(-45deg); margin: -4px;}
.rs1-bottombar p{color: #1F591F; font-size: 12px; font-weight: 500; margin-bottom:5px;}
.rs1-bottombar p strong{font-size: 12px; font-weight: 700; }
.rs1-bottombar .progress{width: 165px; height:6px; border-radius: 12px; font-size: 18px; font-weight: 700;margin: 0;}
.rs1-bottombar .progress .progress-bar{background: #1F591F; border-radius: 12px;}

.rs1-bottombar-success{background: #1F591F; padding: 16px; display: flex; align-items: center; justify-content: space-between;}
.rs1-bottombar-success p{color: #fff; font-size: 12px; font-weight: 500; margin: 0;}
.rs1-bottombar-success p strong{font-size: 12px; font-weight: 700; }
.rs1-bottombar-success button{border: none; outline: none; background: transparent;padding: 0; position: relative; color: #fff; font-size: 12px; font-weight: 500; padding-right: 20px;}
.rs1-bottombar-success button:after{border-right:1px solid #fff;border-bottom:1px solid #fff; width: 8px; height: 8px; content: ''; position: absolute; right: 12px; top: 50%;transform:rotate(-45deg); margin: -4px;}
.rs1StoreCanvas .card-wrap{padding:0; width: 100%; padding: 16px 16px 0;}
.rs1StoreCanvas .card-wrap .sec-title{display: flex; align-items: center; padding: 4px 12px;background: #E9F4E9; border-radius: 50px;margin-bottom: 16px;}
.rs1StoreCanvas .card-wrap .sec-title h4{margin: 0 0 0 8px; font-weight: 700; font-size: 14px; line-height: 19px; letter-spacing: -0.177168px; color: #1F591F;}
.rs1-horiz-scroll {overflow: auto;display: flex;padding: 4px 4px 4px 16px;max-width: calc(100% + 32px); margin:0 -16px 16px;}
.rs1-horiz-scroll .horiz-scroll{flex-direction: column; align-items: center; min-width: 156px; margin: 0 12px 0 0; padding: 24px; border-radius: 8px;}
.rs1-horiz-scroll .horiz-scroll .card{margin: 0; box-shadow: none;}
.rs1-horiz-scroll .horiz-scroll > h3{font-weight: 700; font-size: 24px; line-height: 130%; text-align: center;color: #2B2B2B; margin: 0 0 16px;}
.unlocked-text{padding: 8px 12px; text-align: center; background: linear-gradient(98.96deg, #548AD8 16.92%, #8A4BD3 85.29%); border-radius: 20px; font-weight: 700; font-size: 14px; line-height: 130%; color: #FFFFFF;}
 
/* Buy Again Style */
.BA-wrap{background: linear-gradient(121.02deg, #FFBD9A 0.95%, #FF1961 100%); padding: 12px 16px 1px; position: relative;}
.BA-wrap:before{position: absolute; top: 9px; right: 33px; content: ''; width: 112px; height: 86px; background: url(./img/buy-again-bubbles.png) no-repeat center; background-size: 112px 86px;}
.BA-wrap .sec-title h2{color: #fff;}
.BA-card{background: #fff; padding: 0 16px; border-radius: 12px; position: relative; z-index: 1;}
.BA-card-header{margin: 0 -6px; padding: 8px 0; display: flex; align-items: center;}
.BA-card-header h3{font-size: 14px; color: rgba(223, 83, 83, 1); font-weight: 700; margin: 0 0 2px;}
.BA-card-header h3 small{color: #333333; font-size: 12px; font-weight: 800;}
.BA-card-header h3 span{color: rgba(31, 89, 31, 1);}
.BA-card-header p{font-size: 12px; color: #4a4a4a; font-weight: 500; margin: 0 0 2px;}
.BA-card-body p{font-size: 14px; color: rgba(43, 43, 43, 1); font-weight: 500; margin: 0 0 8px; display: flex; align-items: center;}
.BA-card-body p strong{font-size: 12px; color:rgba(102, 102, 102, 1); font-weight: 700; margin-left: auto;}
.BA-card-body p span{font-size: 14px; color: rgba(43, 43, 43, 0.75); font-weight: 500; margin-right: 8px;}
.BA-card-body a{font-size: 12.5px; text-decoration: none; color: rgba(34, 120, 255, 1); line-height: 16px; font-weight: 500; display: inline-flex; align-items: center;}
.BA-card-body a[aria-expanded="true"] span{width: 32px;overflow: hidden;}
.BA-card-body a[aria-expanded="true"] span:before{content: 'Less'; padding-right: 3px;}
.BA-card-footer{padding: 8px 16px;border-top: 1px solid rgba(105, 105, 105, 0.15); margin: 8px -16px 0; display: flex; align-items: center;}
.BA-card-footer p{font-size: 11px; color: #666666; font-weight: 500; margin: 0 0 4px;}
.BA-card-footer h5{font-size: 14px; color: #2B2B2B; font-weight: 800; margin: 0 ; display: flex; align-items:baseline;}
.BA-card-footer h5 span{font-size: 12px; color: #999999; font-weight: 500; text-decoration: line-through;margin-left: 5px;}
.BA-card-footer h5 strong{font-size: 12px; color: #F88001; font-weight: 700; margin-left: 5px;}
.BA-card-footer button{font-size: 14px; font-weight: 700;padding: 11px 20px; border-radius: 6px;}
.BA-Carousel .carousel-indicators [data-bs-target]{background: #fff;}
.BA-Carousel .carousel-indicators [data-bs-target].active{background: #fff;}

.track-order-bar{background: #fff; box-shadow: 0px -1px 2px rgba(0, 0, 0, 0.1); display: flex; align-items: center; position: fixed; bottom: 52px; height: 48px; width: 100%; z-index: 1001;}
.track-order-bar h4{margin: 0; padding: 12px 8px; background: rgba(248, 128, 1, 0.15); border-radius: 0 30px 30px 0; color: rgba(248, 128, 1, 1); font-size: 12px; line-height: 12px; font-weight: 700; text-align: center; margin-right: 8px; width: 64px; }
.track-order-bar h5{margin: 0; color: #333333; font-size: 12px; line-height: 16px; font-weight: 700;}
.track-order-bar p{margin: 0; color: #333333; font-size: 11px; line-height: 15px; font-weight: 400;}
.track-order-bar button{margin-left: auto; color: #1F591F; font-size: 12px; line-height: 16px; font-weight: 600; padding-right: 20px; background: url(./img/chevron-right-green.png) no-repeat right center; background-size: 16px auto;outline: none;margin-right: 16px; border: 0;}

/**************** Landing Page - Download App Style ****************/

.landing-header{background: linear-gradient(180deg, #9C2F46 0%, #D45F66 100%); display: flex; justify-content: center; align-items: center; font-size: 16px; font-weight: 700; color: #fff;padding: 12px 16px;}
.download-app-btn{background: #F88001; position: fixed; left: 16px; right: 16px;bottom: 24px; z-index: 99; border:0; outline: none; display: flex; justify-content: center; align-items: center; font-size: 16px; font-weight: 700; border-radius: 6px;padding: 14px 16px; color: #fff;}
.landing-content{padding: 32px 16px 72px;font-weight:700; font-size:24px; text-align: center; margin: 0 0 24px; display: flex; flex-direction: column; align-items: center;}
.landing-content .landing-img{display: block; margin: 0 auto  24px;}
.landing-content ul{padding: 0; margin-bottom: 8px; list-style-type: none;}
.landing-content h1{text-align: center; color: #2B2B2B; font-weight: 700;letter-spacing: -0.02em; font-size: 24px; line-height: 32px; margin: 0 0 24px;}
.landing-content ul li{position: relative; text-align: left; padding-left: 22px; color: #2B2B2B; font-weight: 700; font-size: 14px; line-height: 20px; margin: 0 0 8px;}
.landing-content ul li span{color: #F88001;}
.landing-content ul li:before{position: absolute; left: 2px; top: 50%; content: ''; width: 14px; height: 14px; background: #2C7C2C url(./img/icon-tick.png) no-repeat center 2px; border-radius: 50%; background-size: 10px auto; margin-top: -7px;}

.paymethod-card .method-input input:focus + label {font-size: 12px;padding: 8px 10px;}
.p-16{padding: 16px;}
.rs1-bottombar-success {
  background: #1f591f;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rs1-bottombar-success p {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  margin: 0;
}
.rs1-bottombar-success p strong {
  font-size: 12px;
  font-weight: 700;
}
.rs1-bottombar-success button {
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
  position: relative;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding-right: 20px;
}
.rs1-bottombar-success button:after {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  width: 8px;
  height: 8px;
  content: "";
  position: absolute;
  right: 12px;
  top: 50%;
  transform: rotate(-45deg);
  margin: -4px;
}
.cart-bottom-sheet .badge{padding: 2px 5px 1px; display: flex; align-items: center;}
.offcanvas-bottom > .offcanvas-body{padding: 0;}
.editDetailCanvas .offcanvas-body .offcanvas-body{flex-direction: column;}
.homeBottomSheets{position: fixed; z-index: 1032; bottom: 52px; width: 100%;}
.homeBottomSheets > *{position: relative; bottom: auto;}

/* Payment Await Page */
#paytm-checkoutjs, .ptm-checkout-element{font-family: "Gilroy", sans-serif !important;}
.ptm-own-element #paytm-app .payment-mode-warp{min-height: calc(100% - 158px);}
.ptm-own-element #paytm-app .ptm-logo-name{border-radius: 10px ;}
.ptm-own-element #paytm-app .ptm-container input:checked~.ptm-check{background: #f88001; border-color: #f88001;}
.ptm-own-element #paytm-app .ptm-login-btn{background: #f88001;}
.ptm-own-element #paytm-app .ptm-primary{color: #f88001;}

.ptm-own-element #paytm-app .ptm-header-top:after{position: absolute; right: 0; top: 50%; width: 24px; height: 24px; margin-top: -6px; background: url(./img/LOGO-128.png) center no-repeat; background-size: 24px auto; content: '';}
.ptm-own-element #paytm-app .ptm-cross{margin-top: 5px;}

.empty-cart-bottom{padding:8px 16px; border-radius: 6px 6px 0 0; box-shadow: 0 -2px 4px rgb(0 0 0 / 5%); width: 100%;}
.empty-cart-bottom button{width: 100%; font-size: 14px; border-radius: 6px; font-weight: 700;}

.jxlZsF.floating-label{height: auto !important; }
.jxlZsF.floating-label label{padding: 8px 10px !important; font-size: 12px !important;}

.jss7 .MuiInput-underline{padding: 0 !important;}
.jss7 .MuiInput-underline:before{display: none;}
.jss7 .MuiInput-underline input{-webkit-text-fill-color:#2b2b2b !important}

.editableInput{padding: 23px 10px 7px; color: #2b2b2b;}

.method-input .MuiInput-underline{padding: 0 !important;}
.method-input .MuiInput-underline:before{display: none !important;}

.bottom-navbar{width: 100%;max-width: 100vw;padding: 5px 16px 9px;box-shadow: 0 -1px 2px rgba(0,0,0,0.08);justify-content: space-between;height: 52px;}
.changeCategory-offset .categoryWrap{margin-bottom: 0;}

.statusCarousel {padding: 8px 16px 0; background: linear-gradient(180deg, #FFEBC0 0%, #FFFFFF 100%); border-radius: 8px 8px 0px 0px; margin:0 0 50px;}
.statusCarousel .carousel-item{background: #FFFFFF; position: relative; padding: 18px 14px 14px; border: 1px solid rgba(200, 200, 200, 0.5); border-radius: 8px; overflow: hidden; text-align: center ; }
.statusCarousel .carousel-indicators{position: absolute; text-align: center; bottom: -18px;margin: 0; }
.statusCarousel .carousel-indicators .active{background: #DF5353; width: 6px; }
.statusCarousel .carousel-item:before{background: #DF5353;border-radius: 50%; position: absolute; bottom: 52px; left: -11%; content: ''; width: 122%; height: 100%; }
.statusCarousel .carousel-inner > .carousel-item:nth-child(2):before{background: #C89BFF;}
.statusCarousel .carousel-inner > .carousel-item:nth-child(3):before{background: #38A038;}
.statusCarousel .carousel-item h4{position: relative; color: #fff; font-weight: 800; font-size: 24px; line-height: 29px; margin: 0 0 4px;}
.statusCarousel .carousel-item p{position: relative; color: #fff; font-weight: 700; font-size: 14px; line-height: 130%; margin: 0 0 38px;}
.statusCarousel .carousel-item .d-flex{position: relative; color: #F88001; font-weight: 700; font-size: 16px; line-height: 130%; margin: 0;}







